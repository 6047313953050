
import { Options, Vue } from 'vue-property-decorator'
import QuestionTemplateView from '@/components/CollageSurvey.vue'


@Options({
  name: 'App',
  components: {
    QuestionTemplateView
  }
})
export default class App extends Vue {

}
