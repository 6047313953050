export enum SURVEY_STEP_TYPE {
  UNKNOWN = 'unknown',
  WELCOM_STUDY = 'welcome',
  FAMILIARITY = 'familiarity',
  INTRODUCE = 'intro',
  TRIAL = 'trial',
  TRYING = 'trying',
  EXAMPLE = 'example',
  CONFIRMATION = 'confirm',
  INTRODUCE_COLLAGE_MAKER = 'intro_task',
  COLLAGE_MAKER = 'collage_photo',
  COLLAGE_RATING = 'collage_rating',
  RATING_CHARACTERISTIC = 'rating_characteristic',
  DEMOGRAPHIC = 'demographic',
  THANKYOU = 'thank_you',
  LEAVING = 'leaving'
}

export const SURVEY_STEPS: SURVEY_STEP_TYPE[] = [
  SURVEY_STEP_TYPE.UNKNOWN,
  SURVEY_STEP_TYPE.WELCOM_STUDY,
  SURVEY_STEP_TYPE.FAMILIARITY,
  SURVEY_STEP_TYPE.INTRODUCE,
  SURVEY_STEP_TYPE.TRIAL,
  SURVEY_STEP_TYPE.TRYING,
  SURVEY_STEP_TYPE.EXAMPLE,
  SURVEY_STEP_TYPE.CONFIRMATION,
  SURVEY_STEP_TYPE.INTRODUCE_COLLAGE_MAKER,
  SURVEY_STEP_TYPE.COLLAGE_MAKER,
  SURVEY_STEP_TYPE.COLLAGE_RATING,
  SURVEY_STEP_TYPE.RATING_CHARACTERISTIC,
  SURVEY_STEP_TYPE.DEMOGRAPHIC,
  SURVEY_STEP_TYPE.THANKYOU,
  SURVEY_STEP_TYPE.LEAVING
]