import Deneric from 'deneric2'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { TDenericRawData, TFamiliarityItemScore } from '@/entities/Types'

export abstract class BaseSurveyStepData extends Deneric {
  abstract step: SURVEY_STEP_TYPE

  get isCollagePhoto(): boolean {
    return [SURVEY_STEP_TYPE.COLLAGE_RATING, SURVEY_STEP_TYPE.COLLAGE_MAKER].includes(this.step)
  }
}

export class UnknownStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.UNKNOWN
  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String]
    })
    this.fromJson(rawData)
  }
}

export class WelcomStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.WELCOM_STUDY
  isParticipant: boolean = false

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String],
      isParticipant: ['data.is_participant', Boolean]
    })
    this.fromJson(rawData)
  }
}

export class FamiliarityItem extends Deneric {
  name: string = ''
  score: TFamiliarityItemScore = 0
  scoreName: string = ''

  constructor(rawData?: TDenericRawData) {
    super({
      name: ['name', String],
      score: ['score', Number],
      scoreName: ['score_name', String]
    })
    this.fromJson(rawData)
  }
}

export class FamiliarityStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.FAMILIARITY
  entities: FamiliarityItem[] = []

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String],
      entities: ['data.entities', Deneric.Array(FamiliarityItem)]
    })
    this.fromJson(rawData)
  }
}

export class IntroStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.INTRODUCE

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String]
    })
    this.fromJson(rawData)
  }
}

export class TrialStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.TRIAL

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String]
    })
    this.fromJson(rawData)
  }
}

export class ExampleStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.EXAMPLE

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String]
    })
    this.fromJson(rawData)
  }
}

export class PhotoSelected extends Deneric {
  service: number = 0
  id: string = ''
  title!: string
  tags!: string
  description!: string
  url: string = ''

  constructor(rawData?: TDenericRawData) {
    super({
      service: ['service', Number],
      id: ['id', String],
      title: ['title', String],
      tags: ['tags', String],
      description: ['description', String],
      url: ['url', String]
    })
    this.fromJson(rawData)
  }
}

export class CollagePhotoStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.COLLAGE_MAKER
  task: string = ''
  keywords: string[] = []
  photos: PhotoSelected[] = []
  collageUrl: string = ''
  collageDefine: string = ''

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String],
      task: ['data.task', String],
      keywords: ['data.keywords', Deneric.Array(String)],
      photos: ['data.photos', Deneric.Array(PhotoSelected)],
      collageUrl: ['data.collage_url', String],
      collageDefine: ['data.collage_defined', String]
    })
    this.fromJson(rawData)
  }
}

export class CollageRatingStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.COLLAGE_RATING
  task: string = ''
  rating: number = 0
  shortDesc: string = ''

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String],
      task: ['data.task', String],
      rating: ['data.rating', Number],
      shortDesc: ['data.short_desc', String]
    })
    this.fromJson(rawData)
  }
}

export class TryingStepData extends CollagePhotoStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.TRYING

}

export class ConfirmStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.CONFIRMATION
  ready!: boolean
  review!: boolean

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String],
      ready: ['data.ready', Boolean],
      review: ['data.review', Boolean]
    })
    this.fromJson(rawData)
  }
}

export class IntroTaskStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.INTRODUCE_COLLAGE_MAKER
  task: string = ''
  ready!: boolean
  review!: boolean

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String],
      task: ['data.task', String],
      ready: ['data.ready', Boolean],
      review: ['data.review', Boolean]
    })
    this.fromJson(rawData)
  }
}

export class RatingCharacteristicStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.RATING_CHARACTERISTIC
  entities: CharacteristicItem[] = []

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String],
      entities: ['data.entities', Deneric.Array(RatingCharacteristicStepData)]
    })
    this.fromJson(rawData)
  }

}

export class CharacteristicItem extends Deneric {
  name: string = ''
  score: number = 0
  scoreName: string = ''

  constructor(rawData?: TDenericRawData) {
    super({
      name: ['name', String],
      score: ['score', Number],
      scoreName: ['score_name', String]
    })
    this.fromJson(rawData)
  }
}

export class DemographicStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.DEMOGRAPHIC
  gender: string = ''
  age: number = 0
  city: string = ''
  region: string = ''
  maritalStatus: string = ''
  jobStatus: string = ''
  educationLevel: string = ''
  annualIncome: string = ''

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String],
      gender: ['data.gender', String],
      age: ['data.age', Number],
      city: ['data.city', String],
      region: ['data.region', String],
      maritalStatus: ['data.marital_status', String],
      jobStatus: ['data.job_status', String],
      educationLevel: ['data.education_level', String],
      annualIncome: ['data.annual_income', String]
    })
    this.fromJson(rawData)
  }
}

export class ThankYouStepData extends BaseSurveyStepData {
  readonly step: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.THANKYOU
  prolific: string = ''
  comment: string = ''

  constructor(rawData?: TDenericRawData) {
    super({
      step: ['step', String],
      prolific: ['data.prolific', String],
      comment: ['data.comment', String]
    })
    this.fromJson(rawData)
  }

}