
import { Emit, Inject, Options, Prop, Vue } from 'vue-property-decorator'

@Options({
  name: 'Leaving'
})
export default class Leaving extends Vue {
  @Prop({ type: String, required: true })
  readonly visitorId!: string

  @Inject() showError!: Function

  @Emit() finishSurvey(): void {
    // do nothing
  }
}
