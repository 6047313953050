import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex mt-5 align-center justify-center flex-column mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollageMaker = _resolveComponent("CollageMaker")!
  const _component_VBtn = _resolveComponent("VBtn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_CollageMaker, {
      onSubmitCollage: _ctx.submitCollage,
      onSearchImage: _ctx.addImageKeyword,
      ref: "collageMaker",
      "visitor-id": _ctx.visitorId,
      loading: _ctx.loading,
      heading: "Try to search, drag & drop with multiple images",
      "is-trying": ""
    }, null, 8, ["onSubmitCollage", "onSearchImage", "visitor-id", "loading"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_VBtn, {
        onClick: _withModifiers(_ctx.submit, ["prevent"]),
        loading: _ctx.loading,
        variant: "elevated",
        class: "my-1 text-uppercase",
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" I understand the instruction! Continue ")
        ]),
        _: 1
      }, 8, ["onClick", "loading"])
    ])
  ]))
}