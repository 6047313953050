
import { Emit, Inject, mixins, Options } from 'vue-property-decorator'
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import InstructionPreview from '@/components/InstructionPreview/InstructionPreview.vue'
import { ConfirmStepData } from '@/entities/QuestionTemplate'

@Options({
  name: 'ConfirmationStep',
  components: {
    InstructionPreview
  }
})
export default class ConfirmationStep extends mixins(BaseQuestionTemplatePage) {
  protected model: ConfirmStepData = new ConfirmStepData()

  @Inject() showError!: Function

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.COLLAGE_MAKER
  }

  async submitResponse(): Promise<void> {
    const response: boolean = await this.submitReadyData()
    if (response) {
      this.goToNextStep()
    }
  }

  private submitReadyData(): Promise<boolean> {
    this.model.ready = true
    this.model.review = false
    return this.submitStepData(this.model)
  }

  private submitReviewData(): Promise<boolean> {
    this.model.ready = false
    this.model.review = true
    return this.submitStepData(this.model)
  }

  showInstruction(): void {
    (this.$refs.instructionPreview as InstanceType<typeof InstructionPreview>).showDialog()
    this.submitReviewData()
  }
}
