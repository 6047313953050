import { HttpClient } from '@/services/HttpClient'
import { HttpResponse } from '@/services/HttpResponse'
import axios, { AxiosError } from 'axios'

export class QuestionHttpService extends HttpClient<any> {
  protected resolveErrorFn(error: AxiosError<any> | Error): HttpResponse {
    if (axios.isAxiosError(error)) {
      if (error.response?.data.error === 'banned_keyword') {
        const errorData: Record<string, string> = error.response.data || {}
        return HttpResponse.error(errorData.error, 'Banned keyword. Please don’t use a direct search term related to the country or the country’s specific dishes.', errorData)
      }
      return HttpResponse.error(error.code, error.message, error.response ?? error)
    }
    return HttpResponse.error(error.name, error.message, error)
  }

  protected resolveResponseFn(rawData: any): HttpResponse {
    if (rawData.error) {
      if (process.env.NODE_ENV !== 'development') {
        return HttpResponse.error(rawData.error, rawData.error, rawData)
      }
    }
    return HttpResponse.success(rawData)
  }
}