import { Inject, Prop, Provide, Vue } from 'vue-property-decorator'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { CollageService } from '@/services/CollageService'
import { BaseSurveyStepData } from '@/entities/QuestionTemplate'
import { HttpResponse } from '@/services/HttpResponse'
import { IUploadCollageResponse } from '@/entities/Types'

export default abstract class BaseQuestionTemplatePage extends Vue {
  @Prop({ type: String, required: false })
  readonly visitorId!: string
  @Inject() showError!: Function
  protected loading: boolean = false
  protected response: Record<string, any> | null = null
  protected nextCollageTask: string | null = null
  private readonly collageSurveyService: CollageService = CollageService.getInstance()

  abstract goToNextStep(): SURVEY_STEP_TYPE | PromiseLike<void> | null | string

  @Provide()
  async submitStepData(stepData: BaseSurveyStepData): Promise<boolean> {
    console.debug(stepData)
    this.loading = true
    const response: HttpResponse = await this.collageSurveyService.nextSurveyStep(this.visitorId, stepData)
    this.loading = false
    if (response.error) {
      this.showError(response.getErrorInfo()?.message || '')
    }
    this.response = response.getSuccessfulData()
    if (this.response?.url) {
      window.location.href = this.response.url
      return false
    } else if (stepData.isCollagePhoto) {
      this.nextCollageTask = this.response?.next_collage_step_task || null
    } else {
      this.nextCollageTask = null
    }
    console.debug('response', response.getSuccessfulData(), this.response)
    return !response.error
  }

  @Provide()
  async uploadCollageCanvas(canvas: HTMLCanvasElement): Promise<string> {
    const response: HttpResponse<IUploadCollageResponse> = await this.collageSurveyService.uploadPhotoFromCanvas(this.visitorId, canvas)
    return response.getSuccessfulData()?.public_url || ''
  }
}