
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { mixins, Options } from 'vue-property-decorator'
import CollageMaker from '@/components/CollageStep/CollageImageMaker/CollageMaker.vue'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { TryingStepData } from '@/entities/QuestionTemplate'

@Options({
  name: 'TryingCollageStep',
  components: {
    CollageMaker
  }
})
export default class TryingCollageStep extends mixins(BaseQuestionTemplatePage) {
  protected model: TryingStepData = new TryingStepData()

  addImageKeyword(keyword: string): void {
    this.model.keywords.push(keyword)
  }

  async submitCollage(model: TryingStepData): Promise<void> {
    this.model = model.clone()
    this.goToNextStep()
  }

  submit(): void {
    (this.$refs.collageMaker as InstanceType<typeof CollageMaker>).goToNextStep()
    this.goToNextStep()
  }

  async goToNextStep(): Promise<void> {
    let succeedResponse: boolean = !this.visitorId
    if (this.visitorId) {
      succeedResponse = await this.submitStepData(this.model)
    }
    if (succeedResponse) {
      this.$emit('goToNextStep', SURVEY_STEP_TYPE.EXAMPLE)
    }
  }
}
