import { Provide, Vue } from 'vue-property-decorator'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'

export default class SurveyStepMixin extends Vue {
  protected passedStep: number = 0
  private surveyCurrentStep: SURVEY_STEP_TYPE | null = null
  private totalSteps: number = 14

  get progressPercent(): number {
    return Math.ceil(this.passedStep / this.totalSteps * 100)
  }

  get isIntrodution(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.INTRODUCE
  }

  get isExammple(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.EXAMPLE
  }

  get isCollageMaker(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.COLLAGE_MAKER
  }

  get isRating(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.RATING_CHARACTERISTIC
  }

  get isConfirmation(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.CONFIRMATION
  }

  get isWelcomStudyConfirm(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.WELCOM_STUDY
  }

  get isFamiliarity(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.FAMILIARITY
  }

  get isDemographic(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.DEMOGRAPHIC
  }

  get isThankyou(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.THANKYOU
  }

  get isTrial(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.TRIAL
  }

  get isTrying(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.TRYING
  }

  get isIntroduceCollage(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.INTRODUCE_COLLAGE_MAKER
  }

  get isLeaving(): boolean {
    return this.surveyCurrentStep === SURVEY_STEP_TYPE.LEAVING
  }

  @Provide()
  increasePassedStep(): void {
    this.passedStep++
  }

  @Provide()
  passedAllSteps(): void {
    this.passedStep = this.totalSteps
  }

  protected goToStep(step: SURVEY_STEP_TYPE): void {
    this.surveyCurrentStep = step
  }
}