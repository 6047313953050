import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionTemplateView = _resolveComponent("QuestionTemplateView")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { class: "pb-10 bg-grey-lighten-4 overflow-visible" }, {
    default: _withCtx(() => [
      _createVNode(_component_QuestionTemplateView)
    ]),
    _: 1
  }))
}