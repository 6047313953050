
import { Inject, mixins, Options } from 'vue-property-decorator'
import IntroduceCollageStep from './IntroduceCollageStep.vue'
import CollageMaker from './CollageImageMaker/CollageMaker.vue'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import CollageRating from '@/components/CollageStep/CollageRating.vue'
import CollagePhotoStepMixin from '@/components/CollageStep/CollagePhotoStep.mixin'

@Options({
  name: 'WinterCollagePhotoStep',
  components: {
    IntroduceCollageStep,
    CollageMaker,
    CollageRating
  }
})
export default class WinterCollagePhotoStep extends mixins(CollagePhotoStepMixin) {
  @Inject() showError!: Function
  protected collageStep: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.INTRODUCE_COLLAGE_MAKER

  get isIntroduceCollage(): boolean {
    return this.collageStep === SURVEY_STEP_TYPE.INTRODUCE_COLLAGE_MAKER
  }

  get isCollageMaker(): boolean {
    return this.collageStep === SURVEY_STEP_TYPE.COLLAGE_MAKER
  }

  get isCollageRating(): boolean {
    return this.collageStep === SURVEY_STEP_TYPE.COLLAGE_RATING
  }

  nextStep(step: SURVEY_STEP_TYPE): void {
    if (this.isCollageRating) {
      this.goToNextStep()
    } else {
      this.collageStep = step
    }
  }
}
