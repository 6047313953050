
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { Emit, Inject, mixins, Options } from 'vue-property-decorator'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { DemographicStepData } from '@/entities/QuestionTemplate'
import { VForm } from 'vuetify/components'

type TDemographicValidationRules = {
  gender: any[],
  age: any[],
  city: any[],
  region: any[],
  regionOther: any[],
  maritalStatus: any[],
  jobStatus: any[],
  educationLevel: any[],
  anualIncome: any[]
}

@Options({
  name: 'DemographicStep'
})
export default class DemographicStep extends mixins(BaseQuestionTemplatePage) {

  @Inject() showError!: Function
  protected model: DemographicStepData = new DemographicStepData()
  protected otherRegion: string = ''

  get inputRules(): TDemographicValidationRules {
    return {
      gender: [(v: string) => !!v || 'Gender is required'],
      age: [(v: string) => !!v || 'Age is required'],
      city: [(v: string) => !!v || 'City is required'],
      region: [(v: string) => !!v || 'Region is required'],
      regionOther: [(v: string) => !!v || 'Region is required'],
      maritalStatus: [(v: string) => !!v || 'Marital status is required'],
      jobStatus: [(v: string) => !!v || 'Job status is required'],
      educationLevel: [(v: string) => !!v || 'Job status is required'],
      anualIncome: [(v: string) => !!v || 'Anual income is required']
    }
  }

  get educationLevels(): string[] {
    return [
      'Grunnskolen',
      'Videregående skolen',
      'Fagskolen',
      'Universitets- og høgskolen'
    ]
  }

  get jobStatuses(): string[] {
    return [
      'Heltid',
      'Deltid',
      'Arbeidsløs'
    ]
  }

  get isOtherRegion(): boolean {
    return ['Other', 'Annet'].includes(this.model.region)
  }

  get otherLabel(): string {
    // return 'Other'
    return 'Annet'
  }

  async submitForm(): Promise<void> {
    const form: InstanceType<typeof VForm> = this.$refs.form as InstanceType<typeof VForm>
    const { valid } = await form.validate()
    if (valid) {
      const model: DemographicStepData = this.model.clone()
      if (this.isOtherRegion) {
        model.region = `${this.otherLabel}: ${this.otherRegion}`
      }
      this.submitStepData(model)
      this.goToNextStep()
    }
  }

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.THANKYOU
  }
}
