import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1489b34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question-template-view" }
const _hoisted_2 = { class: "question-template-view-container" }
const _hoisted_3 = { class: "d-flex flex-nowrap align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VProgressLinear = _resolveComponent("VProgressLinear")!
  const _component_VAppBar = _resolveComponent("VAppBar")!
  const _component_WelcomToOurStudyStep = _resolveComponent("WelcomToOurStudyStep")!
  const _component_FamiliarityStep = _resolveComponent("FamiliarityStep")!
  const _component_ConfirmationStep = _resolveComponent("ConfirmationStep")!
  const _component_TrialStep = _resolveComponent("TrialStep")!
  const _component_TryingCollagestep = _resolveComponent("TryingCollagestep")!
  const _component_ExampleStep = _resolveComponent("ExampleStep")!
  const _component_CollagePhotostep = _resolveComponent("CollagePhotostep")!
  const _component_ThankYouTemplateView = _resolveComponent("ThankYouTemplateView")!
  const _component_Leaving = _resolveComponent("Leaving")!
  const _component_RatingCharacteristicStep = _resolveComponent("RatingCharacteristicStep")!
  const _component_DemographicStep = _resolveComponent("DemographicStep")!
  const _component_IntroductionStep = _resolveComponent("IntroductionStep")!
  const _component_StartSurvey = _resolveComponent("StartSurvey")!
  const _component_VIcon = _resolveComponent("VIcon")!
  const _component_VSnackbar = _resolveComponent("VSnackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_VAppBar, null, {
      default: _withCtx(() => [
        _createVNode(_component_VProgressLinear, {
          height: 10,
          "model-value": _ctx.progressPercent,
          class: "position-fixed",
          color: "primary",
          style: {"top":"0","left":"0","right":"0"}
        }, null, 8, ["model-value"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isWelcomStudyConfirm)
        ? (_openBlock(), _createBlock(_component_WelcomToOurStudyStep, {
            key: 0,
            onGoToNextStep: _ctx.nextQuestion,
            "visitor-id": _ctx.visitorId
          }, null, 8, ["onGoToNextStep", "visitor-id"]))
        : (_ctx.isFamiliarity)
          ? (_openBlock(), _createBlock(_component_FamiliarityStep, {
              key: 1,
              onGoToNextStep: _ctx.nextQuestion,
              "visitor-id": _ctx.visitorId
            }, null, 8, ["onGoToNextStep", "visitor-id"]))
          : (_ctx.isConfirmation)
            ? (_openBlock(), _createBlock(_component_ConfirmationStep, {
                key: 2,
                onGoToNextStep: _ctx.nextQuestion,
                "visitor-id": _ctx.visitorId
              }, null, 8, ["onGoToNextStep", "visitor-id"]))
            : (_ctx.isTrial)
              ? (_openBlock(), _createBlock(_component_TrialStep, {
                  key: 3,
                  onGoToNextStep: _ctx.nextQuestion,
                  "visitor-id": _ctx.visitorId
                }, null, 8, ["onGoToNextStep", "visitor-id"]))
              : (_ctx.isTrying)
                ? (_openBlock(), _createBlock(_component_TryingCollagestep, {
                    key: 4,
                    onGoToNextStep: _ctx.nextQuestion,
                    "visitor-id": _ctx.visitorId
                  }, null, 8, ["onGoToNextStep", "visitor-id"]))
                : (_ctx.isExammple)
                  ? (_openBlock(), _createBlock(_component_ExampleStep, {
                      key: 5,
                      onGoToNextStep: _ctx.nextQuestion,
                      "visitor-id": _ctx.visitorId
                    }, null, 8, ["onGoToNextStep", "visitor-id"]))
                  : (_ctx.isCollageMaker)
                    ? (_openBlock(), _createBlock(_component_CollagePhotostep, {
                        key: 6,
                        onGoToNextStep: _ctx.nextQuestion,
                        "visitor-id": _ctx.visitorId
                      }, null, 8, ["onGoToNextStep", "visitor-id"]))
                    : (_ctx.isThankyou)
                      ? (_openBlock(), _createBlock(_component_ThankYouTemplateView, {
                          key: 7,
                          onFinishSurvey: _ctx.finishSurvey,
                          "visitor-id": _ctx.visitorId
                        }, null, 8, ["onFinishSurvey", "visitor-id"]))
                      : (_ctx.isLeaving)
                        ? (_openBlock(), _createBlock(_component_Leaving, {
                            key: 8,
                            onFinishSurvey: _ctx.finishSurvey,
                            "visitor-id": _ctx.visitorId
                          }, null, 8, ["onFinishSurvey", "visitor-id"]))
                        : (_ctx.isRating)
                          ? (_openBlock(), _createBlock(_component_RatingCharacteristicStep, {
                              key: 9,
                              onGoToNextStep: _ctx.nextQuestion,
                              "visitor-id": _ctx.visitorId
                            }, null, 8, ["onGoToNextStep", "visitor-id"]))
                          : (_ctx.isDemographic)
                            ? (_openBlock(), _createBlock(_component_DemographicStep, {
                                key: 10,
                                onGoToNextStep: _ctx.nextQuestion,
                                "visitor-id": _ctx.visitorId
                              }, null, 8, ["onGoToNextStep", "visitor-id"]))
                            : (_ctx.isIntrodution)
                              ? (_openBlock(), _createBlock(_component_IntroductionStep, {
                                  key: 11,
                                  onGoToNextStep: _ctx.nextQuestion,
                                  "visitor-id": _ctx.visitorId
                                }, null, 8, ["onGoToNextStep", "visitor-id"]))
                              : (_openBlock(), _createBlock(_component_StartSurvey, {
                                  key: 12,
                                  onGoToNextStep: _ctx.startSurvey,
                                  loading: _ctx.starting
                                }, null, 8, ["onGoToNextStep", "loading"])),
      _createVNode(_component_VSnackbar, {
        modelValue: _ctx.showingError,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showingError) = $event)),
        color: "red",
        "content-class": "snackbar-as-toastr mb-12 mb-md-0",
        timeout: "8000"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_VIcon, {
              class: "me-3",
              icon: "mdi mdi-alert-circle"
            }),
            _createTextVNode(_toDisplayString(_ctx.errorMessage), 1)
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ]))
}