import Deneric from 'deneric2'

type THttpErrorCode = string | number | undefined

export class ErrorInfo extends Error {
  constructor(public title: string, public message: string) {
    super(message)
  }

  static fromError(error: Error): ErrorInfo {
    return new ErrorInfo(error.name, error.message)
  }
}

export class HttpResponse<TData = any> {
  readonly error: boolean
  readonly data: TData
  readonly errorTitle: string
  readonly errorMsg: string
  readonly errorCode: THttpErrorCode

  constructor(error: boolean, data: TData, errorCode: THttpErrorCode, errorMsg: string) {
    this.error = error
    this.data = data
    this.errorCode = errorCode
    this.errorMsg = errorMsg
    this.errorTitle = String(errorCode)
  }

  getErrorInfo(): ErrorInfo | null {
    if (!this.error) return null
    return new ErrorInfo(this.errorTitle, this.errorMsg)
  }

  getSuccessfulData(): TData | null {
    if (this.error) return null
    return this.data
  }

  static success<TData>(data: TData): HttpResponse<TData> {
    return new HttpResponse<TData>(false, data, '', '')
  }

  static error<TErrorData>(errorCode: THttpErrorCode, errorMsg: string, errorData: TErrorData): HttpResponse<TErrorData> {
    return new HttpResponse<TErrorData>(true, errorData, errorCode, errorMsg)
  }

  clone<TData>(newData: TData): HttpResponse<TData> {
    return new HttpResponse<TData>(this.error, newData, this.errorCode, this.errorMsg)
  }
}

export class SearchResponse<TData extends Deneric> extends Deneric {
  data: TData[] = []
  total: number = 0

  constructor(resp?: any, entityClass?: typeof Deneric) {
    super({
      data: ['data', Deneric.Array(entityClass ?? Object)],
      total: ['total', Number]
    })
    this.fromJson(resp)
  }

  static fromParser<T extends Deneric>(resp: any, parser: (data: any) => T): SearchResponse<T> {
    const result: SearchResponse<T> = new SearchResponse<T>(resp)
    result.data = result.data.map((item: any) => parser(item))
    return result
  }
}

type TMgetEntityClass =
  StringConstructor
  | NumberConstructor
  | BooleanConstructor
  | ArrayConstructor
  | ObjectConstructor
  | typeof Deneric

export class MgetResponse<T> extends Deneric {
  map: Record<string, T> = {}

  constructor(props: Record<string, any>, entityClass?: TMgetEntityClass) {
    super({
      map: ['', Deneric.Map(entityClass ?? Object)]
    })
    this.fromJson(props)
  }
}
