
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { Emit, Inject, mixins, Options } from 'vue-property-decorator'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { ExampleStepData } from '@/entities/QuestionTemplate'

type TExampleStep = 'norwegian' | 'italian'

@Options({
  name: 'ExampleStep'
})
export default class ExampleStep extends mixins(BaseQuestionTemplatePage) {
  @Inject() showError!: Function
  protected exampleStep: TExampleStep = 'norwegian'

  get isNorwegianExample(): boolean {
    return this.exampleStep === 'norwegian'
  }

  get isItalianExample(): boolean {
    return this.exampleStep === 'italian'
  }

  async next(): Promise<void> {
    let succeed: boolean = !this.visitorId
    if (this.visitorId) {
      succeed = await this.submitStepData(new ExampleStepData())
    }
    if (succeed) {
      if (this.exampleStep === 'italian') {
        this.goToNextStep()
      } else {
        this.exampleStep = 'italian'
      }
    }
  }

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.CONFIRMATION
  }
}
