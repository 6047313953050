import Deneric from 'deneric2'

export class ImageGalleryItem extends Deneric {
  description: string = ''
  id: string = ''
  largeUrl: string = ''
  mediumUrl: string = ''
  originUrl: string = ''
  service: number = 0
  smallUrl: string = ''
  tags: string = ''
  title: string = ''

  constructor(rawData?: object) {
    super({
      description: ['description', String],
      id: ['id', String],
      largeUrl: ['large_url', String],
      mediumUrl: ['medium_url', String],
      originUrl: ['origin_url', String],
      service: ['service', Number],
      smallUrl: ['small_url', String],
      tags: ['tags', String],
      title: ['title', String]
    })
    this.fromJson(rawData)
  }

  get url(): string {
    return this.mediumUrl || this.smallUrl || this.largeUrl || this.originUrl
  }

}