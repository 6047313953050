
import { Inject, Options, Prop, Vue } from 'vue-property-decorator'
import InstructionPreview from '@/components/InstructionPreview/InstructionPreview.vue'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { IntroTaskStepData } from '@/entities/QuestionTemplate'

@Options({
  name: 'IntroduceCollageStep',
  components: {
    InstructionPreview
  }
})
export default class IntroduceCollageStep extends Vue {
  @Prop(String)
  readonly task!: string

  @Inject() showError!: Function

  @Inject()
  readonly submitStepData!: Function

  protected model: IntroTaskStepData = new IntroTaskStepData()
  protected loading: boolean = false
  protected reviewing: boolean = false

  mounted(): void {
    this.model.task = this.task
  }

  submitReady(): Promise<boolean> {
    this.model.ready = true
    this.model.review = false
    return this.submitStepData(this.model)
  }

  submitReview(): void {
    this.model.ready = false
    this.model.review = true
    this.reviewing = true
    this.submitStepData(this.model)
    this.reviewing = false
  }

  async workOnCollage(): Promise<void> {
    this.loading = true
    const succeed: boolean = await this.submitReady()
    this.loading = false
    if (succeed) {
      this.$emit('workOnCollage', SURVEY_STEP_TYPE.COLLAGE_MAKER)
    }
  }

  backToInstruction(): void {
    this.showInstruction()
  }

  showInstruction(): void {
    this.submitReview();
    (this.$refs.instructionPreview as InstanceType<typeof InstructionPreview>).showDialog()
  }
}
