
import { Emit, mixins, Options, Prop, Watch } from 'vue-property-decorator'
import ImageGallery from '@/components/CollageStep/CollageImageMaker/ImageGallery.vue'
import CollageEditor from '@/components/CollageStep/CollageImageMaker/CollageEditor.vue'
import { CollagePhotoStepData, TryingStepData } from '@/entities/QuestionTemplate'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'

@Options({
  name: 'CollageMaker',
  components: {
    CollageEditor,
    ImageGallery
  }
})
export default class CollageMaker extends mixins(BaseQuestionTemplatePage) {
  @Prop(String)
  readonly task!: string
  @Prop({ type: String, default: 'Foto collage av dine norske spiseopplevelser' })
  readonly heading!: boolean
  @Prop(Boolean)
  readonly isTrying!: boolean

  protected model: CollagePhotoStepData = new CollagePhotoStepData()
  protected loading: boolean = false
  protected invalidPhotos: boolean = false

  @Watch('isTrying', { immediate: true })
  @Watch('task', { immediate: true })
  onTaskChanged(newValue: string, oldValue: string): void {
    if (newValue !== oldValue) {
      this.initCollageStepData(this.task)
    }
  }

  initCollageStepData(task: string = ''): void {
    if (this.isTrying) {
      this.model = new TryingStepData()
    } else {
      this.model = new CollagePhotoStepData()
      this.model.task = task
    }
  }

  async viewCollage(): Promise<void> {
    const collageEditor: InstanceType<typeof CollageEditor> = this.$refs.collageEditor as InstanceType<typeof CollageEditor>
    console.debug(collageEditor.getHtmlCanvas())
    const canvas: HTMLCanvasElement = await collageEditor.getHtmlCanvas()
    const canvasPreview: HTMLDivElement = this.$refs.canvasPreview as HTMLDivElement
    canvasPreview.innerHTML = ''
    canvasPreview.style.width = canvas.style.width
    canvasPreview.style.height = canvas.style.height
    canvasPreview.appendChild(canvas)
  }

  async submitCollage(): Promise<boolean> {
    this.loading = true
    const collageEditor: InstanceType<typeof CollageEditor> = this.$refs.collageEditor as InstanceType<typeof CollageEditor>
    const photoDefined: string = collageEditor.getPhotosDefine()
    this.model.photos = collageEditor.getSelectedPhotos()
    this.model.collageDefine = photoDefined
    const canvas: HTMLCanvasElement = await collageEditor.getHtmlCanvas()
    this.model.collageUrl = await this.uploadCollageCanvas(canvas)
    console.debug(this.model)
    let succeed: boolean = this.isTrying
    if (!this.isTrying) {
      succeed = await this.submitStepData(this.model)
    }
    this.loading = false
    this.$emit('submitCollage', this.model, this.nextCollageTask)
    return succeed
  }

  prepareModelData(): void {
    const collageEditor: InstanceType<typeof CollageEditor> = this.$refs.collageEditor as InstanceType<typeof CollageEditor>
    this.model.photos = collageEditor.getSelectedPhotos()
  }

  get minImagesAtLeast(): number {
    return 7
  }

  async submit(): Promise<void> {
    this.invalidPhotos = false
    this.prepareModelData()
    if (this.model.photos.length < this.minImagesAtLeast) {
      this.invalidPhotos = true
      return
    }
    let succeed: boolean = !this.visitorId
    if (this.visitorId) {
      succeed = await this.submitCollage()
    }
    if (succeed) {
      this.goToNextStep()
    }
  }

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.COLLAGE_RATING
  }

  @Emit('searchImage')
  searchImage(keyword: string): void {
    //
    this.model.keywords.push(keyword)
  }
}
