import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6604c060"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-100 h-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IntroductionStep = _resolveComponent("IntroductionStep")!
  const _component_TrialStep = _resolveComponent("TrialStep")!
  const _component_TryingCollagestep = _resolveComponent("TryingCollagestep")!
  const _component_ExampleStep = _resolveComponent("ExampleStep")!
  const _component_VCard = _resolveComponent("VCard")!
  const _component_VDialog = _resolveComponent("VDialog")!

  return (_openBlock(), _createBlock(_component_VDialog, {
    onAfterLeave: _ctx.onLeaved,
    modelValue: _ctx.showing,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showing) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VCard, { class: "pa-6 bg-grey-lighten-4 dialog-card-container" }, {
          default: _withCtx(() => [
            (_ctx.isIntroduce)
              ? (_openBlock(), _createBlock(_component_IntroductionStep, {
                  key: 0,
                  onGoToNextStep: _ctx.continueToTrial
                }, null, 8, ["onGoToNextStep"]))
              : (_ctx.isTrial)
                ? (_openBlock(), _createBlock(_component_TrialStep, {
                    key: 1,
                    onGoToNextStep: _ctx.continueFromTrial
                  }, null, 8, ["onGoToNextStep"]))
                : (_ctx.isTrying)
                  ? (_openBlock(), _createBlock(_component_TryingCollagestep, {
                      key: 2,
                      onGoToNextStep: _ctx.continueToExample
                    }, null, 8, ["onGoToNextStep"]))
                  : (_ctx.isExample)
                    ? (_openBlock(), _createBlock(_component_ExampleStep, {
                        key: 3,
                        onGoToNextStep: _ctx.hideDialog
                      }, null, 8, ["onGoToNextStep"]))
                    : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["onAfterLeave", "modelValue"]))
}