
import { Inject, mixins, Options, Prop } from 'vue-property-decorator'
import { ThankYouStepData } from '@/entities/QuestionTemplate'
import { VForm } from 'vuetify/components'
import { CollageService } from '@/services/CollageService'
import { HttpResponse } from '@/services/HttpResponse'
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'

@Options({
  name: 'ThankYouTemplate',
  emits: ['finishSurvey']
})
export default class ThankYouTemplate extends mixins(BaseQuestionTemplatePage) {
  @Prop({ type: String, required: true })
  readonly visitorId!: string
  @Inject() showError!: Function
  protected model: ThankYouStepData = new ThankYouStepData()
  protected loading: boolean = false
  protected claimedReward: boolean = false
  protected copied: boolean = false
  protected submitted: boolean = false
  private readonly collageService: CollageService = CollageService.getInstance()

  get validationRules(): any {
    return [
      (v: string) => !!v || 'Prolific is required'
    ]
  }

  mounted(): void {
    if (this.visitorId) {
      // this.getProlificData()
    }
  }

  async claimReward(): Promise<void> {
    await this.submitStepData(this.model)
    this.claimedReward = true
  }

  async getProlificData(): Promise<void> {
    this.loading = true
    const response: HttpResponse = await this.collageService.getProlificData(this.visitorId)
    this.loading = false
    if (!response.error) {
      this.model.prolific = response.data
    }
  }

  async goToNextStep(): Promise<void> {
    const { valid } = await (this.$refs.form as VForm).validate()
    if (valid) {
      if (!this.submitted) {
        await this.submitStepData(this.model)
      }
      this.submitted = true
      this.$emit('finishSurvey', this.model)
    }
  }

  copyToClipboard(): void {
    navigator.clipboard.writeText(this.model.prolific)
    console.debug('copyToClipboard')
    this.copied = true
    setTimeout(() => {
      this.copied = false
    }, 1000)
  }
}
