import { Emit, mixins } from 'vue-property-decorator'
import { CollagePhotoStepData } from '@/entities/QuestionTemplate'
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'

export default class CollagePhotoStepMixin extends mixins(BaseQuestionTemplatePage) {
  protected collagePhotoStepData: CollagePhotoStepData | null = null

  get collageUrl(): string {
    return this.collagePhotoStepData?.collageUrl || ''
  }

  onSubmitCollage(model: CollagePhotoStepData, nextTask: string | null): void {
    this.collagePhotoStepData = model
    this.nextCollageTask = nextTask ?? null
  }

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE | null | string {
    console.debug('NationCollagePhotoStep.goToNextStep', this.response, this.nextCollageTask)
    return this.nextCollageTask
  }
}