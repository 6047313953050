
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { Emit, Inject, mixins, Options } from 'vue-property-decorator'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import SummerCollagePhotoStep from '@/components/CollageStep/SummerCollagePhotoStep.vue'
import NationCollagePhotoStep from '@/components/CollageStep/NationCollagePhotoStep.vue'
import SpringCollagePhotoStep from '@/components/CollageStep/SpringCollagePhotoStep.vue'
import FallCollagePhotoStep from '@/components/CollageStep/FallCollagePhotoStep.vue'
import WinterCollagePhotoStep from '@/components/CollageStep/WinterCollagePhotoStep.vue'

type TCollageStep = 'general' | 'summer' | 'fall' | 'winter' | 'spring'

@Options({
  name: 'CollagePhotoStep',
  components: {
    NationCollagePhotoStep,
    SummerCollagePhotoStep,
    FallCollagePhotoStep,
    WinterCollagePhotoStep,
    SpringCollagePhotoStep
  }
})
export default class CollagePhotostep extends mixins(BaseQuestionTemplatePage) {
  @Inject() showError!: Function
  @Inject()
  readonly increasePassedStep!: Function
  protected collageStep: TCollageStep = 'general'

  get isNation(): boolean {
    return this.collageStep === 'general'
  }

  get isSummer(): boolean {
    return this.collageStep === 'summer'
  }

  get isFall(): boolean {
    return this.collageStep === 'fall'
  }

  get isWinter(): boolean {
    return this.collageStep === 'winter'
  }

  get isSpring(): boolean {
    return this.collageStep === 'spring'
  }

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.DEMOGRAPHIC
  }

  getDefaultNextCollageTask(): TCollageStep | SURVEY_STEP_TYPE {
    switch (this.collageStep) {
      case 'general':
        return 'summer'
      case 'summer':
        return 'fall'
      case 'fall':
        return 'winter'
      case 'winter':
        return 'spring'
      case 'spring':
      default:
        return SURVEY_STEP_TYPE.DEMOGRAPHIC
    }
  }

  onNexStep(responseNextStep: string | null): void {
    console.debug('responseNextStep', responseNextStep)
    // let nextCollageTask: string = this.getDefaultNextCollageTask()
    let nextCollageTask: string = SURVEY_STEP_TYPE.DEMOGRAPHIC
    if (responseNextStep) {
      nextCollageTask = responseNextStep
    }
    console.debug('nextCollageTask', nextCollageTask)
    if (nextCollageTask === SURVEY_STEP_TYPE.DEMOGRAPHIC) {
      this.goToNextStep()
    } else {
      this.collageStep = nextCollageTask as TCollageStep
    }
    this.increasePassedStep()
  }
}
