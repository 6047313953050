import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IntroduceCollageStep = _resolveComponent("IntroduceCollageStep")!
  const _component_CollageMaker = _resolveComponent("CollageMaker")!
  const _component_CollageRating = _resolveComponent("CollageRating")!

  return (_ctx.isIntroduceCollage)
    ? (_openBlock(), _createBlock(_component_IntroduceCollageStep, {
        key: 0,
        onWorkOnCollage: _ctx.nextStep,
        "visitor-id": _ctx.visitorId,
        task: "general"
      }, null, 8, ["onWorkOnCollage", "visitor-id"]))
    : (_ctx.isCollageMaker)
      ? (_openBlock(), _createBlock(_component_CollageMaker, {
          key: 1,
          onSubmitCollage: _ctx.onSubmitCollage,
          onGoToNextStep: _ctx.nextStep,
          "visitor-id": _ctx.visitorId,
          task: "general"
        }, null, 8, ["onSubmitCollage", "onGoToNextStep", "visitor-id"]))
      : (_ctx.isCollageRating)
        ? (_openBlock(), _createBlock(_component_CollageRating, {
            key: 2,
            onGoToNextStep: _ctx.nextStep,
            "visitor-id": _ctx.visitorId,
            "collage-url": _ctx.collageUrl,
            task: "general"
          }, null, 8, ["onGoToNextStep", "visitor-id", "collage-url"]))
        : _createCommentVNode("", true)
}