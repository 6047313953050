
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { Inject, mixins, Options } from 'vue-property-decorator'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { TrialStepData } from '@/entities/QuestionTemplate'

@Options({
  name: 'TrialStep'
})
export default class TrialStep extends mixins(BaseQuestionTemplatePage) {
  @Inject() showError!: Function
  protected model: TrialStepData = new TrialStepData()
  private trying: boolean = false

  async goToNextStep(): Promise<void> {
    let succeedResponse: boolean = !this.visitorId
    if (this.visitorId) {
      succeedResponse = await this.submitStepData(this.model)
    }
    if (succeedResponse) {
      this.$emit('goToNextStep', SURVEY_STEP_TYPE.EXAMPLE)
    }
  }

  async tryToPhotoCollage(): Promise<void> {
    this.trying = true
    let succeedResponse: boolean = !this.visitorId
    if (this.visitorId) {
      succeedResponse = await this.submitStepData(this.model)
    }
    this.trying = false
    if (succeedResponse) {
      this.$emit('goToNextStep', SURVEY_STEP_TYPE.TRYING)
    }
  }
}
