import { QuestionHttpService } from '@/services/QuestionHttpService'
import { HttpResponse, SearchResponse } from '@/services/HttpResponse'
import { FullSurveyStepRequest } from '@/entities/Request'
import { ImageGalleryItem } from '@/entities/CollageMakerTemplate'
import { BaseSurveyStepData } from '@/entities/QuestionTemplate'
import { v4 as uuidv4 } from 'uuid'
import { IUploadCollageResponse } from '@/entities/Types'

export class CollageService {
  protected static instance: CollageService
  private ssid!: string
  private httpClient: QuestionHttpService = new QuestionHttpService({
    baseURL: APP_CONFIG.api
  })

  static getInstance(): CollageService {
    if (!CollageService.instance) {
      CollageService.instance = new CollageService()
    }
    return CollageService.instance
  }

  generateVisitorUuid(): string {
    return uuidv4()
  }

  startSurvey(visitor: string): Promise<HttpResponse> {
    return this.httpClient.post('/survey_response/start', {
      visitor
    }, (rawData: any) => {
      this.ssid = rawData.session_id
      return rawData
    })
  }

  nextSurveyStep(visitor: string, stepData: BaseSurveyStepData): Promise<HttpResponse> {
    const bodyStepData: object = {
      visitor,
      step: {
        data: {},
        ...stepData.toJson()
      }
    }
    console.debug('nextSurveyStep', bodyStepData)
    return this.httpClient.post('/survey_response/next', bodyStepData, (rawData: any) => {
      // if ((stepData as any).task === 'general') {
      //   rawData.next_collage_step_task = 'spring'
      // } else if ((stepData as any).task === 'spring') {
      //   rawData.next_collage_step_task = 'summer'
      // }
      return rawData
    }, {
      headers: {
        // Cookies: `cis_ssid=${this.ssid}`
      }
    })
  }

  fullSurveyData(fullStepRequest: FullSurveyStepRequest): Promise<HttpResponse> {
    return this.httpClient.post('/survey_response/next', fullStepRequest.toJson(), (rawData: any) => {
      return rawData
    })
  }

  searchImage(page: number, keyword?: string): Promise<HttpResponse<SearchResponse<ImageGalleryItem>>> {
    const queryParams: string = this.httpClient.buildParams({
      sk: APP_CONFIG.secret_key,
      keyword,
      page,
      size: 50
    })
    return this.httpClient.get(`/public_photo/search?${queryParams}`, (rawData: object) => {
      console.log(rawData)
      return new SearchResponse(rawData, ImageGalleryItem)
    })
  }

  uploadPhotoFromCanvas(visitorId: string, canvas: HTMLCanvasElement): Promise<HttpResponse> {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    function dataURItoBlob(dataURI: string): Blob {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString: string
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1])
      else
        byteString = unescape(dataURI.split(',')[1])
      // write the bytes of the string to a typed array
      const ia: Uint8Array = new Uint8Array(byteString.length)
      for (let i: number = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ia], { type: 'image/png' })
    }

    const dataUrl: string = canvas.toDataURL()
    const blob: File = dataURItoBlob(dataUrl) as File
    return this.uploadPhoto(visitorId, blob)
  }

  uploadPhoto(visitorId: string, upload: File): Promise<HttpResponse<IUploadCollageResponse | null>> {
    const formData: FormData = new FormData()
    formData.append('upload', upload)
    formData.append('visitor_id', visitorId)
    formData.append('sk', APP_CONFIG.secret_key as string)
    return this.httpClient.post('/photo/upload', formData, (rawData?: Record<string, any>) => {
      if (rawData?.result) {
        return rawData.data as IUploadCollageResponse
      }
      return null
    }, {
      data: {
        visitor_id: visitorId,
        sk: APP_CONFIG.secret_key
      }
    })
  }

  getProlificData(visitorId: string): Promise<HttpResponse<string>> {
    return this.httpClient.post('/survey_response/gen_code_for_prolific', { visitor: visitorId }, (rawData: Record<string, string>) => {
      return rawData.code as string
    })
  }
}