
import { mixins, Options, Prop, Provide } from 'vue-property-decorator'
import IntroductionStep from '@/components/IntroductionStep/IntroductionStep.vue'
import FamiliarityStep from '@/components/Familiarity/FamiliarityStep.vue'
import ConfirmationStep from '@/components/Confirmation/ConfirmationStep.vue'
import WelcomToOurStudyStep from '@/components/Welcome/WelcomToOurStudyStep.vue'
import Leaving from '@/components/Leaving/Leaving.vue'
import TrialStep from '@/components/Trial/TrialStep.vue'
import RatingCharacteristicStep from '@/components/Rating/RatingCharacteristicStep.vue'
import DemographicStep from '@/components/Demographic/DemographicStep.vue'
import ThankYouTemplateView from '@/components/ThankYou/ThankYou.vue'
import SurveyStepMixin from '@/components/SurveyStep.mixin'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import CollagePhotostep from '@/components/CollageStep/CollagePhotoStep.vue'
import StartSurvey from '@/components/StartSurvey/StartSurvey.vue'
import ExampleStep from '@/components/Example/ExampleStep.vue'
import TryingCollageStep from '@/components/Trying/TryingCollagestep.vue'
import { CollageService } from '@/services/CollageService'
import { HttpResponse } from '@/services/HttpResponse'

@Options({
  name: 'QuestionTemplateView',
  components: {
    Leaving,
    CollagePhotostep,
    IntroductionStep,
    FamiliarityStep,
    ExampleStep,
    ConfirmationStep,
    WelcomToOurStudyStep,
    TrialStep,
    RatingCharacteristicStep,
    DemographicStep,
    ThankYouTemplateView,
    StartSurvey,
    TryingCollagestep: TryingCollageStep
  }
})
export default class CollageSurvey extends mixins(SurveyStepMixin) {
  @Prop({ type: Boolean, default: false })
  readonly hideFooter!: boolean
  protected loading: boolean = false
  protected starting: boolean = false
  protected visitorId: string = ''
  private readonly collageSurveyService: CollageService = CollageService.getInstance()
  private showingError: boolean = false
  private errorMessage: string = ''


  @Provide()
  showError(message: string): void {
    this.showingError = !!message
    this.errorMessage = message
  }

  nextQuestion(nextStep: SURVEY_STEP_TYPE): void {
    console.debug('nextQuestion', nextStep)
    this.goToStep(nextStep)
    if (this.isLeaving || this.isThankyou) {
      this.passedAllSteps()
    } else {
      this.increasePassedStep()
    }
  }

  async startSurvey(nextStep: SURVEY_STEP_TYPE): Promise<void> {
    this.starting = true
    this.visitorId = this.collageSurveyService.generateVisitorUuid()
    const response: HttpResponse = await this.collageSurveyService.startSurvey(this.visitorId)
    this.starting = false
    if (response.error) {
      this.showError(response.getErrorInfo()?.message || '')
    } else {
      this.nextQuestion(nextStep)
    }
  }

  async finishSurvey(): Promise<void> {
    alert('Finish survey!!!')
    // @ts-ignore
    window.close()
  }
}
