
import { Options, Vue } from 'vue-property-decorator'
import jQuery from 'jquery'
import 'jquery-ui'
import { ImageGalleryItem } from '@/entities/CollageMakerTemplate'
import sortBy from 'lodash/sortBy'
import html2canvas from 'html2canvas'
import { PhotoSelected } from '@/entities/QuestionTemplate'

type TImageSelection = {
  element: HTMLDivElement,
  photo: ImageGalleryItem
}

@Options({
  name: 'CollageEditor'
})
export default class CollageEditor extends Vue {
  images: Map<string, TImageSelection> = new Map()
  selectedImageElement: HTMLDivElement | null = null
  activatorMenu: any | null = null
  isMenuShow: boolean = false
  protected showPreviewCollage: boolean = false

  mounted(): void {
    console.debug(this.$refs.menu)
    // this.isMenuShow = true
  }

  get renderImages(): HTMLImageElement[] {
    return Object.values(this.images.entries())
  }

  onDrop(event: DragEvent): void {
    console.debug('onDrop', event)
    const data: DataTransfer | null = event.dataTransfer
    if (data) {
      const imageRaw: object = JSON.parse(data.getData('image'))
      console.debug('fileAsString', imageRaw)
      const imageGallery: ImageGalleryItem = new ImageGalleryItem(imageRaw)
      this.addImageToCanvas(imageGallery, event.offsetX, event.offsetY)
    }
  }

  getMaxZindex(): number {
    const elementZindex: number[] = Array.from(jQuery('.image-container')).map((item: HTMLElement) => Number(item.style.zIndex))
    return Math.max(...elementZindex) || 0
  }

  addImageToCanvas(imageGallery: ImageGalleryItem, dx: number, dy: number): void {
    const canvasElement: HTMLCanvasElement = this.$refs.canvas as HTMLCanvasElement
    if (canvasElement) {
      const imageContainer: HTMLDivElement = document.createElement('div')
      imageContainer.classList.add('image-container', `image-container-${imageGallery.id}`)
      const imageLength: number = this.$el.querySelectorAll(`.image-container-${imageGallery.id}`).length
      imageContainer.id = `${imageGallery.id}-image-container-${imageLength}`
      imageContainer.style.position = 'absolute'
      imageContainer.style.zIndex = this.getMaxZindex() + 1 + ''
      imageContainer.style.top = dy + 'px'
      imageContainer.style.left = dx + 'px'
      const image: HTMLImageElement = document.createElement('img')
      image.src = imageGallery.url
      image.onload = () => {
        const width: number = image.width / 2
        imageContainer.style.width = width + 'px'
        imageContainer.style.height = image.height/2 + 'px'
        image.style.width = '100%'
        image.style.height = '100%'
      }
      const deleteBtn: HTMLSpanElement = document.createElement('span')
      deleteBtn.textContent = '×'
      deleteBtn.classList.add('ui-delete-image')
      deleteBtn.onclick = () => {
        imageContainer.remove()
        this.images.delete(imageContainer.id)
      }

      const rotateContainer: HTMLDivElement = document.createElement('div')
      rotateContainer.classList.add('rotate-container')
      rotateContainer.appendChild(image)
      // @ts-ignore
      jQuery(rotateContainer).rotatable()

      rotateContainer.appendChild(deleteBtn)
      imageContainer.appendChild(rotateContainer)
      this.prepareImage(canvasElement.appendChild(imageContainer))
      this.selectImageElement(imageContainer)
      this.images.set(imageContainer.id, {
        element: imageContainer,
        photo: imageGallery
      })
    }
  }

  private prepareImage(imgContainerElement: HTMLDivElement): HTMLDivElement {
    console.debug(jQuery(imgContainerElement))
    jQuery(imgContainerElement)
      .draggable({
        containment: 'parent',
        start: () => {
          this.activatorMenu = null
          this.isMenuShow = false
        }
      })
      .find('.rotate-container').resizable({
        containment: this.$el,
        minHeight: 30,
        minWidth: 30,
        resize: (event: Event, UI: any) => {
          // imgContainerElement.style.width = UI.size.width
          // imgContainerElement.style.height = UI.size.height
          jQuery(imgContainerElement).css({
            width: UI.size.width,
            height: UI.size.height
          })
        }
      })
    imgContainerElement.oncontextmenu = (event: Event) => {
      event.preventDefault()
      this.activatorMenu = event.target
      this.isMenuShow = true
      this.selectImageElement(imgContainerElement)
      console.debug(event)
    }
    imgContainerElement.onclick = (event: Event) => {
      event.preventDefault()
      event.stopImmediatePropagation()
    }
    imgContainerElement.onmousedown = (event: Event) => {
      event.preventDefault()
      this.selectImageElement(imgContainerElement)
      console.debug(event)
    }
    return imgContainerElement
  }

  selectImageElement(imageElement: HTMLDivElement): void {
    jQuery(imageElement).addClass('current-selected').siblings().removeClass('current-selected')
    this.selectedImageElement = imageElement
  }

  getImagesSortByZindex(): HTMLDivElement[] {
    return sortBy(Array.from(this.$el.querySelectorAll('.image-container')), 'style.zIndex')
  }

  bringToFront(): void {
    if (this.selectedImageElement) {
      const currentZindex: number = Number(this.selectedImageElement.style.zIndex) || 0
      let highestZIndex: number = this.getMaxZindex()
      this.$el.querySelectorAll('.image-container').forEach((image: HTMLElement) => {
        const zIndex: number = Number(image.style.zIndex)
        if (zIndex > currentZindex) {
          image.style.zIndex = String(zIndex - 1)
          highestZIndex = zIndex
        } else if (zIndex === currentZindex) {
          highestZIndex++
        }
      })
      this.selectedImageElement.style.zIndex = highestZIndex.toString()
    }
  }

  bringForward(): void {
    const zStep: number = 1
    const orderImages: HTMLDivElement[] = this.getImagesSortByZindex()
    if (this.selectedImageElement) {
      const selectedImageIndex: number = orderImages.indexOf(this.selectedImageElement)
      const previousElement: HTMLDivElement = orderImages[selectedImageIndex + 1]
      const previousImageElement: HTMLElement | null = document.getElementById(previousElement?.id)
      if (previousImageElement) {
        this.selectedImageElement.style.zIndex = String(Number(this.selectedImageElement.style.zIndex) + zStep)
        previousImageElement.style.zIndex = String(Math.max(1, Number(previousImageElement.style.zIndex) - zStep))
      }
    }
  }

  sendToBack(): void {
    if (this.selectedImageElement) {
      const currentZindex: number = Number(this.selectedImageElement.style.zIndex) || 0
      this.$el.querySelectorAll('.image-container').forEach((image: HTMLElement) => {
        const zIndex: number = Number(image.style.zIndex)
        if (zIndex <= currentZindex) {
          image.style.zIndex = String(zIndex + 1)
        }
      })
      this.selectedImageElement.style.zIndex = '1'
    }
  }

  sendBackward(): void {
    const zStep: number = -1
    const orderImages: HTMLDivElement[] = this.getImagesSortByZindex()
    if (this.selectedImageElement) {
      const selectedImageIndex: number = orderImages.indexOf(this.selectedImageElement)
      const previousElement: HTMLDivElement = orderImages[selectedImageIndex - 1]
      const previousImageElement: HTMLElement | null = document.getElementById(previousElement?.id)
      if (previousImageElement) {
        this.selectedImageElement.style.zIndex = String(Math.max(1, Number(this.selectedImageElement.style.zIndex) + zStep))
        previousImageElement.style.zIndex = String(Math.max(1, Number(previousImageElement.style.zIndex) - zStep))
      }
    }
  }

  getSelectedPhotos(): PhotoSelected[] {
    return Array.from(this.images.values()).map((item: TImageSelection) => {
      const photoSelected: PhotoSelected = new PhotoSelected()
      photoSelected.id = item.photo.id
      photoSelected.service = item.photo.service
      photoSelected.description = item.photo.description
      photoSelected.tags = item.photo.tags
      photoSelected.url = item.photo.url
      return photoSelected
    })
  }

  getPhotosDefine(): string {
    return Array.from(this.images.values()).map((item: TImageSelection) => {
      const element: HTMLDivElement = item.element
      const rotateElement: HTMLDivElement | null = element.querySelector('.rotate-container')
      return [
        element.style.top,
        element.style.left,
        element.style.zIndex,
        element.style.width,
        element.style.height,
        rotateElement ? rotateElement.style.transform : ''
      ].join(',')
    }).join(';')
  }

  getHtmlCanvas(): Promise<HTMLCanvasElement> {
    return new Promise<HTMLCanvasElement>((resolve: Function, reject: Function) => {
      const element: any = this.$refs.canvas as HTMLElement
      jQuery(element).find('.ui-rotatable-handle, .ui-delete-image, .ui-resizable-handle').attr('data-html2canvas-ignore', 'true')
      html2canvas(element, {
        allowTaint: true,
        foreignObjectRendering: false,
        useCORS: true
      }).then((canvas: HTMLCanvasElement) => {
        console.debug(canvas)
        resolve(canvas)
      }).catch((error: Error) => {
        reject(error)
      })
    })
  }
}
