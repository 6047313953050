import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NationCollagePhotoStep = _resolveComponent("NationCollagePhotoStep")!
  const _component_SummerCollagePhotoStep = _resolveComponent("SummerCollagePhotoStep")!
  const _component_FallCollagePhotoStep = _resolveComponent("FallCollagePhotoStep")!
  const _component_WinterCollagePhotoStep = _resolveComponent("WinterCollagePhotoStep")!
  const _component_SpringCollagePhotoStep = _resolveComponent("SpringCollagePhotoStep")!

  return (_ctx.isNation)
    ? (_openBlock(), _createBlock(_component_NationCollagePhotoStep, {
        key: 0,
        onGoToNextStep: _ctx.onNexStep,
        "visitor-id": _ctx.visitorId
      }, null, 8, ["onGoToNextStep", "visitor-id"]))
    : (_ctx.isSummer)
      ? (_openBlock(), _createBlock(_component_SummerCollagePhotoStep, {
          key: 1,
          onGoToNextStep: _ctx.onNexStep,
          "visitor-id": _ctx.visitorId
        }, null, 8, ["onGoToNextStep", "visitor-id"]))
      : (_ctx.isFall)
        ? (_openBlock(), _createBlock(_component_FallCollagePhotoStep, {
            key: 2,
            onGoToNextStep: _ctx.onNexStep,
            "visitor-id": _ctx.visitorId
          }, null, 8, ["onGoToNextStep", "visitor-id"]))
        : (_ctx.isWinter)
          ? (_openBlock(), _createBlock(_component_WinterCollagePhotoStep, {
              key: 3,
              onGoToNextStep: _ctx.onNexStep,
              "visitor-id": _ctx.visitorId
            }, null, 8, ["onGoToNextStep", "visitor-id"]))
          : (_ctx.isSpring)
            ? (_openBlock(), _createBlock(_component_SpringCollagePhotoStep, {
                key: 4,
                onGoToNextStep: _ctx.onNexStep,
                "visitor-id": _ctx.visitorId
              }, null, 8, ["onGoToNextStep", "visitor-id"]))
            : _createCommentVNode("", true)
}