import { createApp } from 'vue'
import App from './App.vue'
// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import jQuery from 'jquery'
import 'jquery-ui/dist/jquery-ui.min.js'
import 'jquery-ui/dist/themes/base/jquery-ui.min.css'
import 'jquery-ui-rotatable/jquery.ui.rotatable.min.js'
import 'jquery-ui-rotatable/jquery.ui.rotatable.css'

// @ts-ignore
window.$ = window.jQuery = jQuery

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/typedef
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  }
})

createApp(App)
  .use(vuetify)
  .mount('#app')
