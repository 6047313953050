
import { Emit, Inject, mixins, Options } from 'vue-property-decorator'
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { CharacteristicItem, RatingCharacteristicStepData } from '@/entities/QuestionTemplate'

@Options({
  name: 'RatingCharacteristicStep'
})
export default class RatingCharacteristicStep extends mixins(BaseQuestionTemplatePage) {
  invalidRecord: string[] = []
  @Inject() showError!: Function
  protected model: RatingCharacteristicStepData = new RatingCharacteristicStepData()
  protected ratingOptions: any[] = [
    {
      value: 'contemporary',
      label: 'Moderne',
      rate: 0
    },
    {
      value: 'cheerful',
      label: 'Munter',
      rate: 0
    },
    {
      value: 'leader',
      label: 'Leder',
      rate: 0
    },
    {
      value: 'real',
      label: 'Ekte',
      rate: 0
    },
    {
      value: 'upper_class',
      label: 'Overklasse',
      rate: 0
    },
    {
      value: 'spirited',
      label: 'Engasjert',
      rate: 0
    },
    {
      value: 'exciting',
      label: 'Spennende',
      rate: 0
    },
    {
      value: 'glamorous',
      label: 'Glamorøs',
      rate: 0
    },
    {
      value: 'up_to_date',
      label: 'oppdatert',
      rate: 0
    },
    {
      value: 'secure',
      label: 'Trygg',
      rate: 0
    },
    {
      value: 'sincere',
      label: 'Oppriktig',
      rate: 0
    },
    {
      value: 'tough',
      label: 'Vanskelig',
      rate: 0
    },
    {
      value: 'western',
      label: 'Vestlig',
      rate: 0
    },
    {
      value: 'imaginative',
      label: 'Fantasifull',
      rate: 0
    },
    {
      value: 'technical',
      label: 'Teknisk',
      rate: 0
    },
    {
      value: 'small-town',
      label: 'Småby',
      rate: 0
    },
    {
      value: 'honest',
      label: 'Ærlig',
      rate: 0
    },
    {
      value: 'unique',
      label: 'Unik',
      rate: 0
    },
    {
      value: 'smooth',
      label: 'Glatt',
      rate: 0
    },
    {
      value: 'wholesome',
      label: 'Sunn',
      rate: 0
    }
  ]

  get scoreNames(): Record<number, string> {
    return {
      0: '',
      1: 'Ikke i det hele tatt beskrivende',
      2: 'Litt beskrivende',
      3: 'Noe beskrivende',
      4: 'Middels beskrivende',
      5: 'Ekstremt beskrivende'
    }
  }

  get highScoreSelection(): string[] {
    return this.ratingOptions.filter((option: Record<string, string | number>) => option.rate >= 3)
  }

  isInvalid(optionValue: string): boolean {
    return this.invalidRecord.includes(optionValue)
  }

  validate(optionValue: string): void {
    if (this.isInvalid(optionValue)) {
      this.invalidRecord = this.invalidRecord.filter((invalidValue: string) => invalidValue !== optionValue)
    }
  }

  async submitResponse(): Promise<void> {
    const isValid: boolean = this.validation()
    console.debug(isValid)
    if (this.validation()) {
      this.model.entities = this.ratingOptions.map((ratingOption: Record<string, string | number>) => {
        const item: CharacteristicItem = new CharacteristicItem()
        item.name = ratingOption.label as string
        item.score = ratingOption.rate as number
        item.scoreName = this.scoreNames[ratingOption.rate as number]
        return item
      })
      const response: boolean = await this.submitStepData(this.model)
      if (response) {
        this.goToNextStep()
      }
    }
  }

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.DEMOGRAPHIC
  }

  protected validation(): boolean {
    let isValid: boolean = true
    this.invalidRecord = this.ratingOptions.filter((option: Record<string, string | number>) => option.rate <= 0).map((option: Record<string, string>) => option.value)
    if (this.invalidRecord.length) {
      isValid = false
    }
    return isValid
  }
}
