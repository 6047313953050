
import { Emit, Inject, mixins, Options, Prop, Watch } from 'vue-property-decorator'
import { CollageRatingStepData } from '@/entities/QuestionTemplate'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { VForm } from 'vuetify/components'

@Options({
  name: 'CollageRating'
})
export default class CollageRating extends mixins(BaseQuestionTemplatePage) {
  @Prop(String)
  readonly task!: string

  @Prop({ type: String, default: 'Hvor lett var det for deg å finne bilder om DIN NORSKE SPISEOPPLEVELSE?' })
  readonly heading!: boolean

  @Prop(String)
  readonly collageUrl!: string

  protected model: CollageRatingStepData = new CollageRatingStepData()
  protected valid: boolean = true
  protected invalidRating: boolean = false

  @Inject() showError!: Function

  @Watch('task', { immediate: true })
  onTaskChanged(newValue: string, oldValue: string): void {
    if (newValue !== oldValue) {
      this.initCollageStepData(this.task)
    }
  }

  initCollageStepData(task: string = ''): void {
    this.model = new CollageRatingStepData()
    this.model.task = task
  }

  get descriptionRules(): any {
    return [
      (v: string) => (!!v && v.length >= 50) || 'Description must be at least 50 charaters'
    ]
  }

  get ratingRules(): any {
    return [
      (v: number) => v > 0 || 'Rating must be required'
    ]
  }

  @Watch('model.rating')
  validateRating(): void {
    this.invalidRating = this.model.rating === 0
  }

  async submit(): Promise<void> {
    const { valid } = await (this.$refs.form as InstanceType<typeof VForm>).validate()
    this.validateRating()
    if (valid && !this.invalidRating) {
      const succeed: boolean = await this.submitStepData(this.model)
      if (succeed) {
        this.goToNextStep()
      }
    }
  }

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.COLLAGE_RATING
  }
}
