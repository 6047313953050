
import { Emit, Inject, mixins, Options } from 'vue-property-decorator'
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { FamiliarityItem, FamiliarityStepData } from '@/entities/QuestionTemplate'
import { TFamiliarityItemScore } from '@/entities/Types'

@Options({
  name: 'FamiliarityStep'
})
export default class FamiliarityStep extends mixins(BaseQuestionTemplatePage) {

  @Inject() showError!: Function

  protected model: FamiliarityStepData = new FamiliarityStepData()
  protected selected: Record<string, number> = {
    greatBritain: 0,
    norway: 0,
    france: 0,
    italy: 0,
    japan: 0,
    germany: 0,
    china: 0
  }
  protected invalidRecord: string[] = []

  isInvalid(recordName: string): boolean {
    return this.invalidRecord.includes(recordName)
  }

  validate(nation: string): void {
    if (this.isInvalid(nation)) {
      this.invalidRecord = this.invalidRecord.filter((invalidNation: string) => invalidNation !== nation)
    }
  }

  get valueFamiliarities(): string[] {
    return Object.keys(this.selected)
  }

  get scoringLabels(): Record<number, string> {
    return {
      0: '',
      1: 'Not at all familiar',
      2: 'Silightly familiar',
      3: 'Somewhat familiar',
      4: 'Moderately familiar',
      5: 'Extremely familiar'
    }
  }

  get nationLabels(): Record<string, string> {
    return {
      greatBritain: 'Storbritannia',
      norway: 'Norge',
      france: 'Frankrike',
      italy: 'Italia',
      japan: 'Japan',
      germany: 'Tyskland',
      china: 'Kina'
    }
  }

  protected validation(): boolean {
    let isValid: boolean = true
    this.invalidRecord = Object.keys(this.selected).filter((key: string) => this.selected[key] === 0)
    if (this.invalidRecord.length) {
      isValid = false
    }
    return isValid
  }

  get highScoreSelection(): string[] {
    return Object.keys(this.selected).filter((key: string) => this.selected[key] >= 1)
  }

  private prepareModelData(): void {
    this.model.entities = this.valueFamiliarities.map((nation: string) => {
      const item: FamiliarityItem = new FamiliarityItem()
      item.score = this.selected[nation] as TFamiliarityItemScore
      item.name = this.nationLabels[nation]
      item.scoreName = this.scoringLabels[this.selected[nation]]
      return item
    })
  }

  async submitResponse(): Promise<void> {
    if (this.validation()) {
      this.prepareModelData()
      const response: boolean = await this.submitStepData(this.model)
      if (response) {
        this.goToNextStep()
      }
    }
  }

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.INTRODUCE
  }
}
