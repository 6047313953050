
import { Options, Prop, Vue } from 'vue-property-decorator'
import { ImageGalleryItem } from '@/entities/CollageMakerTemplate'
import { debounce } from 'lodash'
import { CollageService } from '@/services/CollageService'
import { ErrorInfo, HttpResponse, SearchResponse } from '@/services/HttpResponse'

@Options({
  name: 'ImageGallery',
  emits: ['searchImage']
})
export default class ImageGallery extends Vue {
  @Prop({ type: String })
  readonly nationName!: string
  onInputKeyword: Function = debounce(() => {
    this.searchImages()
  }, 700)
  protected images: ImageGalleryItem[] = []
  protected keyword: string = ''
  protected page: number = 1
  protected total: number = 0
  private readonly collageSurveyService: CollageService = CollageService.getInstance()
  private loading: boolean = false
  private error: ErrorInfo | null = null

  async searchImages(): Promise<void> {
    this.error = null
    this.images = []
    this.total = 0
    if (!this.keyword) {
      return
    }
    try {
      this.loading = true
      this.$emit('searchImage', this.keyword)
      const response: Record<string, any> | null = await this.fetchImages(this.keyword, 1)
      if (response) {
        this.applyImages(response.data, this.page)
      }
      this.loading = false
    } catch (error: any) {
      console.debug(error)
    }
  }

  async loadMore(): Promise<void> {
    if (this.images.length < this.total && !this.loading) {
      try {
        this.loading = true
        const response: Record<string, any> | null = await this.fetchImages(this.keyword, this.page + 1)
        this.loading = false
        if (response) {
          this.applyImages(response.data, this.page)
        }
      } catch (error: any) {
        console.debug(error)
      }
    }
  }

  onGalleryScrolling(event: Event): void {
    const scrollingElement: HTMLDivElement | null = event.target as HTMLDivElement
    if (scrollingElement) {
      const scrollDown: number = (scrollingElement.scrollTop + scrollingElement.clientHeight) / scrollingElement.scrollHeight
      if (scrollDown >= 0.55) {
        this.loadMore()
      }
    }
  }

  async fetchImages(keyword: string, page?: number): Promise<Record<string, any> | null> {
    if (keyword) {
      if (page && page !== this.page) {
        this.page = page
      }
      const response: HttpResponse<SearchResponse<ImageGalleryItem>> = await this.collageSurveyService.searchImage(this.page, this.keyword)
      if (response.error) {
        this.error = response.getErrorInfo()
        return null
      }
      this.total = response.data.total
      return response.getSuccessfulData()
    }
    return null
  }

  applyImages(responseData: ImageGalleryItem[], page: number): void {
    if (page === 1) {
      this.images = responseData
    } else {
      this.images = this.images.concat(responseData)
    }
  }

  onStartDrag(image: ImageGalleryItem, event: DragEvent): void {
    console.debug(image, event)
    if (event.dataTransfer && event.target) {
      event.dataTransfer.setData('image', JSON.stringify(image.toJson()))
    }
  }

  onDragEnd(image: ImageGalleryItem, event: DragEvent): void {
    if (event.dataTransfer && event.target) {
      (event.target as HTMLImageElement).style.width = '100%';
      (event.target as HTMLImageElement).style.height = '100%'
      event.dataTransfer.setData('image', JSON.stringify(image.toJson()))
      console.debug(event.dataTransfer)
    }
  }
}
