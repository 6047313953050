import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76d532f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collage-editor-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VListItem = _resolveComponent("VListItem")!
  const _component_VList = _resolveComponent("VList")!
  const _component_VMenu = _resolveComponent("VMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onDragstart: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent","stop"])),
      onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
      onDrop: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onDrop && _ctx.onDrop(...args)), ["prevent"])),
      ref: "canvas",
      draggable: "true",
      class: "position-relative bg-grey-lighten-2 h-100 w-100 overflow-hidden"
    }, null, 544),
    _createVNode(_component_VMenu, {
      modelValue: _ctx.isMenuShow,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isMenuShow) = $event)),
      ref: "menu",
      "click-outside": "",
      class: "contextmenu",
      activator: _ctx.activatorMenu
    }, {
      default: _withCtx(() => [
        _createVNode(_component_VList, null, {
          default: _withCtx(() => [
            _createVNode(_component_VListItem, {
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.bringToFront()), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Bring to front")
              ]),
              _: 1
            }),
            _createVNode(_component_VListItem, {
              onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.sendToBack()), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Send to back")
              ]),
              _: 1
            }),
            _createVNode(_component_VListItem, {
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.bringForward()), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Bring forward")
              ]),
              _: 1
            }),
            _createVNode(_component_VListItem, {
              onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.sendBackward()), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Send backward")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "activator"])
  ]))
}