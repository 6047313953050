
import { Emit, Inject, mixins, Options } from 'vue-property-decorator'
import BaseQuestionTemplatePage from '@/components/BaseQuestionTemplatePage'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import { IntroStepData } from '@/entities/QuestionTemplate'

@Options({
  name: 'Introduction'
})
export default class IntroductionStep extends mixins(BaseQuestionTemplatePage) {

  @Inject() showError!: Function

  @Emit('goToNextStep')
  goToNextStep(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.TRIAL
  }

  async submitResponse(): Promise<void> {
    let succeedResponse: boolean = !this.visitorId
    if (this.visitorId) {
      succeedResponse = await this.submitStepData(new IntroStepData())
    }
    if (succeedResponse) {
      this.goToNextStep()
    }
  }
}
