
import { Emit, Inject, Options, Vue } from 'vue-property-decorator'
import IntroductionStep from '@/components/IntroductionStep/IntroductionStep.vue'
import TrialStep from '@/components/Trial/TrialStep.vue'
import ExampleStep from '@/components/Example/ExampleStep.vue'
import { SURVEY_STEP_TYPE } from '@/entities/Enums'
import TryingCollageStep from '@/components/Trying/TryingCollagestep.vue'

@Options({
  name: 'InstructionPreview',
  components: {
    IntroductionStep,
    TrialStep,
    TryingCollagestep: TryingCollageStep,
    ExampleStep
  }
})
export default class InstructionPreview extends Vue {
  @Inject() showError!: Function
  protected showing: boolean = false
  protected previewStep: SURVEY_STEP_TYPE = SURVEY_STEP_TYPE.INTRODUCE

  get isIntroduce(): boolean {
    return this.previewStep === SURVEY_STEP_TYPE.INTRODUCE
  }

  get isTrial(): boolean {
    return this.previewStep === SURVEY_STEP_TYPE.TRIAL
  }

  get isTrying(): boolean {
    return this.previewStep === SURVEY_STEP_TYPE.TRYING
  }

  get isExample(): boolean {
    return this.previewStep === SURVEY_STEP_TYPE.EXAMPLE
  }

  showDialog(): void {
    this.showing = true
  }

  hideDialog(): void {
    this.showing = false
  }

  onLeaved(): void {
    this.previewStep = SURVEY_STEP_TYPE.INTRODUCE
  }

  continueToTrial(): void {
    this.previewStep = SURVEY_STEP_TYPE.TRIAL
  }

  continueFromTrial(step: SURVEY_STEP_TYPE): void {
    this.previewStep = step
  }

  continueToExample(): void {
    this.previewStep = SURVEY_STEP_TYPE.EXAMPLE
  }

  @Emit()
  tryingCollage(): SURVEY_STEP_TYPE {
    return SURVEY_STEP_TYPE.TRYING
  }
}
