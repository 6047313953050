import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { HttpResponse } from './HttpResponse'

export type TResolveResponseFn<T> = (rawData: T) => HttpResponse
export type TResolveErrorFn<T> = (error: AxiosError<T> | Error) => HttpResponse
export type TParam = Record<string, TParamValue | TParamValue[]>
type TParamValue = string | number | boolean
type TParseDataFn<TRawResponse = any, TData = any> = (rawData: TRawResponse) => TData
const DEFAULT_PARSER: TParseDataFn = (rawData: any) => rawData

export abstract class HttpClient<TRawResponse> {
  private instance: AxiosInstance
  protected abstract resolveResponseFn(rawData: TRawResponse): HttpResponse
  protected abstract resolveErrorFn(error: AxiosError<TRawResponse> | Error): HttpResponse

  constructor(options: AxiosRequestConfig = {}) {
    this.instance = axios.create({
      withCredentials: true,
      ...options
    })
  }

  buildParams(params: any): string {
    const resolveParams: string[] = []
    Object.keys(params ?? {}).forEach((key: string) => {
      let val: any = params[key]
      if (val === undefined || val === null) {
        val = ''
      }
      if (Array.isArray(val)) {
        resolveParams.push(`${key}=${val.join(',')}`)
      } else {
        resolveParams.push(`${key}=${val}`)
      }
    })
    return resolveParams.join('&')
  }

  protected parseResponse<TData>(rawResp: AxiosResponse<TRawResponse>, parser: (rawData: any) => TData): HttpResponse<TData> {
    const resp: HttpResponse<TData> = this.resolveResponseFn(rawResp.data)
    return resp.clone<TData>(parser(resp.data))
  }

  protected processResponse<TData>(promise: Promise<AxiosResponse>, parser: (rawData: any) => TData): Promise<HttpResponse<TData>> {
    return promise
      .then((rawResp: AxiosResponse<TRawResponse>) => this.parseResponse(rawResp, parser))
      .catch((error: AxiosError<TRawResponse>) => {
        console.log('catch')
        // Process when axios throw error with response
        if (error.response) {
          // return this.parseResponse(error.response, parser)
          return this.resolveErrorFn(error)
        }
        // Process when axios throw error without response
        const resp: HttpResponse<TData> = this.resolveErrorFn(error)
        return resp.clone<TData>(parser(resp.data))
      })
  }

  get<TData, TNewRawResponse = TRawResponse>(url: string, parseDataFn: TParseDataFn<TNewRawResponse, TData> = DEFAULT_PARSER, options?: AxiosRequestConfig): Promise<HttpResponse<TData>> {
    return this.processResponse<TData>(this.instance.get(url, options), parseDataFn)
  }

  post<TData, TNewRawResponse = TRawResponse>(url: string, data: object = {}, parseDataFn: TParseDataFn<TNewRawResponse, TData> = DEFAULT_PARSER, options?: AxiosRequestConfig): Promise<HttpResponse<TData>> {
    return this.processResponse<TData>(this.instance.post(url, data, options), parseDataFn)
  }

  put<TData, TNewRawResponse = TRawResponse>(url: string, data: object = {}, parseDataFn: TParseDataFn<TNewRawResponse, TData> = DEFAULT_PARSER, options?: AxiosRequestConfig): Promise<HttpResponse<TData>> {
    return this.processResponse<TData>(this.instance.put(url, data, options), parseDataFn)
  }

  delete<TData, TNewRawResponse = TRawResponse>(url: string, parseDataFn: TParseDataFn<TNewRawResponse, TData> = DEFAULT_PARSER, options?: AxiosRequestConfig): Promise<HttpResponse<TData>> {
    return this.processResponse<TData>(this.instance.delete(url, options), parseDataFn)
  }
}
